import { useEffect, useState } from "react"
import { useMediaQuery } from "react-responsive"

interface IUseIsMobile {
  isMobile: boolean
  isTablet: boolean
  isDesktop: boolean
  isLarge: boolean
  isExtraLarge: boolean
}

export const useScreenSize = (): IUseIsMobile => {
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [isTablet, setIsTablet] = useState<boolean>(false)
  const [isDesktop, setIsDesktop] = useState<boolean>(false)
  const [isLarge, setIsLarge] = useState<boolean>(false)
  const [isExtraLarge, setIsExtraLarge] = useState<boolean>(false)

  const isMobileDevice = useMediaQuery({ query: '(min-width: 640px)' })
  const isTabletDevice = useMediaQuery({ query: '(min-width: 768px)' })
  const isDesktopDevice = useMediaQuery({ query: '(min-width: 1024px)' })
  const isLargeDevice = useMediaQuery({ query: '(min-width: 1280px)' })
  const isExtraLargeDevice = useMediaQuery({ query: '(min-width: 1536px)' })

  useEffect(() => {
    setIsMobile(isMobileDevice)
    setIsTablet(isTabletDevice)
    setIsDesktop(isDesktopDevice)
    setIsLarge(isLargeDevice)
    setIsExtraLarge(isExtraLargeDevice)
  }, [isMobileDevice, isTabletDevice, isDesktopDevice, isLargeDevice, isExtraLargeDevice])

  return { isMobile: isMobile, isTablet: isTablet, isDesktop: isDesktop, isLarge: isLarge, isExtraLarge: isExtraLarge }
}