import { ReactElement, ReactNode } from "react"
import { ErrorBoundary } from 'react-error-boundary'
import { ThemeContextProvider } from "../../context"
import { BoxedButton } from "../Buttons"

export const MainWrapper = ({ children }: { children: ReactNode }) => {

  return (
    <ThemeContextProvider>
      <div className="w-full bg-zinc-900 text-zinc-50">
        <ErrorBoundary onError={myErrorHandler} fallback={<div className="w-screen h-screen flex flex-col pt-24 text-pink-500 justify-center">
          <h3>Something went wrong!</h3>
          <p>Try to refresh the page</p>
        </div>}>
          {children}
        </ErrorBoundary>
      </div>
    </ThemeContextProvider>
  )
}

const ErrorFallback = ({ error, resetErrorBoundary }: { error: Error, resetErrorBoundary: () => void }): ReactElement => {
  return (
    <div className="w-screen h-screen flex flex-col pt-24 text-pink-500 justify-center">
      <h3>Something went wrong!</h3>
      <p>{error.message}</p>
      <BoxedButton onClick={resetErrorBoundary} label={"Try again"} />
    </div>
  )
}

const myErrorHandler = (error: Error, info: { componentStack: string }) => {
  // Do something with the error
  console.log(error.message);

  // E.g. log to an error logging client here
}