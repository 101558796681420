
import { AnimatedImage } from "../../components/AnimatedImage"
import { Footer } from "../../components/Footer"
import { Gallery } from "../../components/Gallery"
import { InfiniteText } from "../../components/InfiniteText"
import { Name } from "../../components/Name"
import { Navigation } from "../../components/Navigation"
import { Discography } from "../../components/Section/Discography"
import { LatestAlbum } from "../../components/Section/LatestAlbum"
import { useThemeContext } from "../../context"

export const HomeDesktop = () => {
  const { setThemeColor } = useThemeContext()

  return (
    <>
      <div className="w-full h-screen">

        <div className="w-10/12 xl:w-8/12 mx-auto relative h-screen">
          {/* <Navigation /> */}
          <div className="w-full absolute bottom-0">
            <Name name={"Sergio"} lastName={'Mauricio'} desc={['MUSICIAN', 'COMPOSER', 'ARRANGER']} />
          </div>
          <AnimatedImage />
        </div>
      </div>

      <LatestAlbum />

      <Discography slides={3} />

      <InfiniteText mainText={'Sergio Mauricio'} secondText={`MUSICIAN COMPOSER ARRANGER `} />

      <Gallery />

      <Footer />
    </>
  )
}