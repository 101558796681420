
import { AnimatedImage } from '../../components/AnimatedImage'
import { Footer } from '../../components/Footer'
import { Gallery } from '../../components/Gallery'
import { InfiniteText } from '../../components/InfiniteText'
import { Name } from "../../components/Name"
import { Discography } from '../../components/Section/Discography'
import { LatestAlbum } from '../../components/Section/LatestAlbum'


export const HomeMobile = () => {

  return (
    <>
      <div className="w-full h-screen flex justify-end flex-col relative">
        <Name name={"Sergio"} lastName={'Mauricio'} desc={['MUSICIAN', 'COMPOSER', 'ARRANGER']} />
        <AnimatedImage />
      </div>

      <LatestAlbum />

      <Discography slides={1.5} />

      <InfiniteText mainText={'Sergio Mauricio'} secondText={`MUSICIAN COMPOSER ARRANGER `} />

      <Gallery />

      <Footer />
    </>
  )
}