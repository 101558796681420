import React, { useLayoutEffect, useRef } from 'react'
import { useScreenSize } from '../../hooks/useScreenSize'
import { gsap } from "gsap";
import { useInView } from 'react-intersection-observer';
import { Section } from '../Section';

interface IGalleryObject {
  src: string
  alt: string
  isLink?: boolean
  link?: string
}

export const Gallery = () => {
  const { isTablet } = useScreenSize();
  const galleryRef = useRef(null)
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const timeline = gsap.timeline();
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      if (inView)
        timeline.from(galleryRef.current, { y: 20, opacity: 0, duration: .6, stagger: 1.4 })
    })
    return () => ctx.revert()
  }, [inView])

  const imageCollection: IGalleryObject[] = [
    {
      src: '/images/gallery/gallery_01.png',
      alt: 'As a musician, I work from wherever I can feel the music'
    },
    {
      src: '/images/gallery/gallery_02.png',
      alt: 'As a musician, I work from wherever I can feel the music'
    },
    {
      src: '/images/gallery/gallery_03.png',
      alt: 'As a musician, I work from wherever I can feel the music'
    },
    {
      src: '/images/gallery/gallery_04.png',
      alt: 'As a musician, I work from wherever I can feel the music'
    },
  ]

  if (isTablet) {
    return (
      <Section section='Gallery' className='mb-16'>
        <div className='w-full ' ref={ref}>
          <div className="grid grid-cols-6 gap-4">
            {imageCollection.map((x, i) => {
              switch (i) {
                case 2:
                  return (
                    <div key={`gallery-image-${i}`} className='col-span-4 max-h-80 overflow-hidden flex justify-center items-center'>
                      <img src={x.src} alt={x.alt} className='w-full object-cover h-full' />
                    </div>
                  )
                  break;
                case 3:
                  return (
                    <div key={`gallery-image-${i}`} className='col-span-2 max-h-80 overflow-hidden flex justify-center items-center'>
                      <img src={x.src} alt={x.alt} className='w-full object-cover h-full' />
                    </div>
                  )
                  break;

                default:
                  return (
                    <div key={`gallery-image-${i}`} className='col-span-3 max-h-44 overflow-hidden flex justify-center items-center'>
                      <img src={x.src} alt={x.alt} className='w-full object-cover h-full' />
                    </div>
                  )
                  break;
              }
            })}
          </div>
        </div>
      </Section>
    )
  }

  return (
    <Section section='Gallery'>
      <div className='w-full ' ref={ref}>
        <div ref={galleryRef} className='grid grid-cols-1 gap-2'>
          {imageCollection.map((x, i) => {
            if (x.isLink) {
              return (
                <a href={x.link} className='mb-2 flex-shrink-0 inline-flex flex-grow-0 w-full' target={'_self'} key={`gallery-image-${i}`}>
                  <img src={x.src} alt={x.alt} />
                </a>
              )
            } else {
              return (
                <div key={`gallery-image-${i}`} className='col-span-1 max-h-44 overflow-hidden flex justify-center items-center'>
                  <img src={x.src} alt={x.alt} className='w-full object-cover h-full' />
                </div>
              )
            }
          })}
        </div>
      </div>
    </Section>
  )
}
