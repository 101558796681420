import React, { useLayoutEffect, useRef } from 'react'
import faceImage from '../../assets/images/face.png'
import { gsap } from "gsap";

export const AnimatedImage = () => {
  const image = useRef(null)
  const mainTimeline = gsap.timeline();

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      mainTimeline
        .from(image.current, {
          y: 40,
          opacity: 0,
          scale: 0.9,
          duration: 16,
          delay: 1
        })
    })
    return () => ctx.revert()
  }, [])

  return (
    <div className='absolute top-0 left-0 w-full h-screen flex flex-col justify-center items-center'>
      <img src={faceImage} alt='' className='w-full h-auto mix-blend-lighten' ref={image} />
    </div>
  )
}
