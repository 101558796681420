import { gsap } from "gsap";
import { useLayoutEffect, useRef } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGetTextColor } from "../../hooks";

export interface IInfiniteText {
  mainText: string
  secondText: string
  translation?: number
}

export const InfiniteText = ({ mainText, secondText }: IInfiniteText) => {
  gsap.registerPlugin(ScrollTrigger);
  const textColor = useGetTextColor(40)
  const mainTexts = Array(20).fill(mainText.trim())
  const secondaryTexts = Array(20).fill(secondText.trim())
  const mainTextTimeline = gsap.timeline();
  const mainInfiniteText = useRef(null)
  const secondInfiniteText = useRef(null)
  const wrapper = useRef(null)
  const transition = -400

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      mainTextTimeline
        .to(mainInfiniteText.current, {
          xPercent: transition,
          duration: 8,
          ease: "power2",
          scrollTrigger: {
            markers: false,
            start: 'top',
            end: "bottom",
            scrub: 2,
          }
        })
        .from(secondInfiniteText.current, {
          xPercent: transition,
          duration: 8,
          ease: "power2",
          scrollTrigger: {
            markers: false,
            start: 'top',
            end: "bottom",
            scrub: 2,
          }
        })
    })
    return () => ctx.revert()
  }, [])

  return (
    <div ref={wrapper} className="w-full overflow-hidden flex flex-col justify-start items-center h-auto my-6 sm:my-12 md:my-24 lg:my-24">
      <div ref={mainInfiniteText} className="w-full flex flex-row flex-nowrap">
        {mainTexts.map((x, i) => <div className="inline-flex flex-grow flex-shrink-0 whitespace-pre-wrap font-serif text-4xl sm:text-5xl lg:text-6xl font-bold" key={i}>{` ${x} `}</div>)}
      </div>
      <div ref={secondInfiniteText} className={`w-full flex flex-row flex-nowrap `}>
        {secondaryTexts.map((x, i) => (
          <div key={i} className='inline-flex flex-row justify-start flex-nowrap mr-4'>
            <div className={`${textColor} whitespace-nowrap font-sans text-2xl sm:text-3xl lg:text-4xl font-light`}>
              {`${x}`}
            </div>
            <div className="h-full flex-grow w-auto flex flex-col flex-shrink-0 justify-center items-center pl-4">
              <div className="w-1 h-1 bg-lime-600 rounded-full" />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
