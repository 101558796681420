import { useEffect, useState } from "react"
import { AnimatedCursor } from 'react-animated-cursor-ts'
import { Spinner } from "../components/Spinner"
import { useGetHexColor } from "../hooks"
import { useScreenSize } from "../hooks/useScreenSize"
import { HomeDesktop } from "./HomeDesktop"
import { HomeMobile } from "./HomeMobile"

export const Main = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { isTablet } = useScreenSize()
  const cursorColor = useGetHexColor()


  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 100)
    return () => { clearTimeout(timer) }
  }, [])


  if (isTablet) {
    return (
      <>
        {isLoading && <Spinner />}
        <AnimatedCursor color={cursorColor} />
        <HomeDesktop />
      </>
    )
  }

  // Mobile
  return (
    <>
      {isLoading && <Spinner />}
      <HomeMobile />
    </>
  )
}