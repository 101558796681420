import { gsap } from "gsap";
import { ReactElement, useLayoutEffect, useRef } from "react";
import { useGetTextColor } from "../../hooks";


export interface IName {
  name: string
  lastName?: string
  desc?: string | string[]
}
export const Name = ({ name, lastName, desc }: IName): ReactElement => {
  const textColor = useGetTextColor()
  const full = useRef(null)
  const nameRef = useRef(null)
  const lastRef = useRef(null)
  const titles = useRef<HTMLDivElement>(null)
  const timeline = gsap.timeline();

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      timeline.from(nameRef.current, { y: 150, duration: 1, delay: 2 })
        .from(lastRef.current, { y: 150, duration: 1 }, "-=.8")
        .from(titles.current?.childElementCount && titles.current?.childElementCount > 1 ? titles.current?.children : titles.current, { y: 150, opacity: 1, duration: 1, stagger: .2 }, "-=.6")
    })
    return () => ctx.revert()
  }, [])

  return (
    <div className="px-4 mb-8" ref={full}>
      <div className={`w-full font-serif text-6xl sm:text-8xl text-stone-50`}>
        <div className="font-thin overflow-hidden">
          <div className="w-full" ref={nameRef} >{name}</div>
        </div>
        {lastName &&
          <div className="font-bold -top-2 relative overflow-hidden">
            <div ref={lastRef}>{lastName}</div>
          </div>
        }
      </div>
      {desc &&
        <div className={`w-full font-sans-serif text-sm sm:text-2xl overflow-hidden font-thin flex flex-row ${textColor}  ${Array.isArray(desc) ? 'justify-between' : 'justify-start'}`} ref={titles}>
          {!Array.isArray(desc)
            ? <div>{desc}</div>
            : desc.map((x, k) => (
              <div key={k}>{x}</div>
            ))}
        </div>
      }

    </div>
  )
}