import { Autoplay, EffectCoverflow, Pagination } from 'swiper'
import "swiper/css"
import "swiper/css/effect-coverflow"
import "swiper/css/pagination"
import { Swiper, SwiperSlide } from 'swiper/react'
import { Section } from '.'

export const Discography = ({ slides }: { slides: number }) => {
  return (
    <Section section='Discography' title='Walking through'>
      <Swiper
        slidesPerView={slides}
        modules={[Pagination, Autoplay, EffectCoverflow]}
        effect={"coverflow"}
        initialSlide={1}
        className='w-full h-fit'
        grabCursor={true}
        centeredSlides={true}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
      >
        <SwiperSlide>
          <img src='/images/albums/album_01.png' alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src='/images/albums/album_02.png' alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src='/images/albums/album_03.png' alt='' />
        </SwiperSlide>
      </Swiper>
    </Section>
  )
}
