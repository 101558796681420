import { useGetBackgroundColor, useGetFromGradient, useGetTextColor } from "../../hooks"

export const Spinner = () => {
  const color = useGetFromGradient()
  return (
    <div className="w-screen h-screen flex justify-center items-center flex-col">
      <div className="flex justify-center items-center h-screen">
        <div className={`relative w-24 h-24 animate-spin rounded-full bg-gradient-to-r ${color} to-zinc-900 `}>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[94px] h-[94px] bg-zinc-900 rounded-full "></div>
        </div>
      </div>
    </div>
  )
}
