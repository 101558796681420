import { createContext, ReactElement, ReactNode, useContext, useState } from "react";

export interface IThemeContext {
  themeColor: string;
  setThemeColor: (newColor: string) => void;
}

const ThemeContext = createContext<any>(undefined);

export const ThemeContextProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [themeColor, setThemeColor] = useState<'green' | 'red' | 'blue'>('green');

  return (
    <ThemeContext.Provider value={{ themeColor, setThemeColor }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = (): IThemeContext => useContext(ThemeContext);
