import { Spotify } from 'react-spotify-embed'
import { Section } from '.'
import { useScreenSize } from '../../hooks/useScreenSize'

export const LatestAlbum = () => {
  const { isTablet } = useScreenSize()
  return (
    <Section section='Latest Album' title='Cold Dream' className='mt-12'>
      <div className='w-full flex flex-col justify-center items-center font-light text-sm mb-6'>
        <h4 className='mb-2'>You can found it here:</h4>
        <div className='w-1/2 mx-auto flex flex-row justify-evenly'>
          <img src='/images/icons/Spotify_logo_without_text.svg' alt='Spotify logo' width={36} />
          <img src='/images/icons/Apple_Music_logo.svg' alt='Spotify logo' width={120} />
        </div>
      </div>
      <Spotify
        wide
        height={isTablet ? undefined : 425}
        link="https://open.spotify.com/album/2oDW1V8CuxCqNWsKPGtJT3?si=yoFF-LxVStSDxg2wdrLovQ"
      />
    </Section>
  )
}
