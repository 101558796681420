import { ReactNode, useLayoutEffect, useRef, useState } from 'react';
import { useGetFromGradient, useGetTextColor } from '../../hooks';
import { useInView } from 'react-intersection-observer';
import { gsap } from "gsap";

export interface ISection {
  children: ReactNode;
  title?: string;
  section?: string;
  fullWidth?: boolean
  hasGradient?: boolean
  className?: string
}

export const Section = ({ children, title, section, fullWidth, hasGradient, className }: ISection) => {
  const textColor = useGetTextColor()
  const gradientColor = useGetFromGradient()
  const sectionRef = useRef(null)
  const titleRef = useRef(null)
  const childrenRef = useRef(null)
  const timeline = gsap.timeline();
  const [isFirstRender, setIsFirstRender] = useState(true)
  const { ref, inView } = useInView({
    threshold: 0,
  });

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      if (isFirstRender && inView) {
        timeline.from(sectionRef.current, { y: 10, opacity: 0, duration: .8, delay: .8 })
          .from(titleRef.current, { y: 10, opacity: 0, duration: .8 }, "-=.4")
          .from(childrenRef.current, { y: 5, opacity: 0, duration: 1.2 }, "-=.4")
        setIsFirstRender(false);
      }
    })
    return () => ctx.revert()
  }, [inView])


  return (
    <section className={`w-full sm:${fullWidth ? 'w-full' : 'w-10/12'} md:${fullWidth ? 'w-full' : 'w-10/12'} lg:${fullWidth ? 'w-full' : 'w-8/12'}  mx-auto flex-col flex justify-start items-center relative py-4 ${fullWidth ? 'px-0' : 'px-4'} ${hasGradient ? `bg-gradient-to-b ${gradientColor}` : ``} ${className && className}`} ref={ref}>
      {(title || section) &&
        <div className='w-full flex flex-col mb-6'>
          {section && <h4 className={` w-full text-center font-sans text-sm sm:text-lg font-light  ${textColor}`} ref={sectionRef}>{section}</h4>}
          {title && <h2 className={`w-full text-center text-warmGray-100 font-serif text-2xl sm:text-3xl font-bold`} ref={titleRef}>{title}</h2>}
        </div>
      }
      <div ref={childrenRef} className='w-full h-auto flex flex-col justify-center'>
        {children}
      </div>
    </section>
  )
}
