import { FiFacebook, FiTwitter, FiInstagram } from "react-icons/fi"
import { Email } from "react-obfuscate-email"
import { useGetHexColor, useGetTextColor } from "../../hooks"
import { useScreenSize } from "../../hooks/useScreenSize"
import { Section } from "../Section"

export const Footer = () => {
  const { isTablet } = useScreenSize()

  if (isTablet) {
    return (
      <Section hasGradient section='Get in touch' fullWidth>
        <div className='font-sans mb-8 text-warmGray-100 w-full flex justify-between items-center'>
          <EmailLink />
          <SocialLinks />
        </div>
      </Section>
    )
  }

  return (
    <Section hasGradient section='Get in touch' fullWidth>
      <div className='font-sans mb-8 text-warmGray-100'>
        <EmailLink isMobile />
        <SocialLinks />
      </div>
    </Section>
  )
}

const EmailLink = ({ isMobile }: { isMobile?: boolean }) => {
  const textColor = useGetTextColor();
  return (
    <div className={`w-full text-lg text-center ${isMobile ? 'mb-6' : ''}`}>
      <Email email="info@sergiomauricio.com">info<span className={textColor}>@</span>sergiomauricio.com</Email>
    </div>
  )
}

const SocialLinks = () => {
  const hexColor = useGetHexColor();
  return (
    <div className='w-full flex flex-row justify-around'>
      <FiFacebook color={hexColor} size={24} />
      <FiTwitter color={hexColor} size={24} />
      <FiInstagram color={hexColor} size={24} />
    </div>
  )
}