import { useEffect, useState } from "react";
import { useThemeContext } from "../context";
import * as tailwindColors from 'tailwindcss/colors'

export const useGetBackgroundColor = (): string => {
  const [color, setColor] = useState<string>('')
  const { themeColor } = useThemeContext()

  useEffect(() => {
    switch (themeColor) {
      case 'green':
        setColor('bg-lime-600')
        break;
      case 'blue':
        setColor('bg-blue-700')
        break;
      case 'red':
        setColor('bg-rose-700')
        break;
      default:
        setColor('bg-lime-600')
        break;
    }
  }, [themeColor])
  return color;
}

export const useGetTextColor = (opacity?: number): string => {
  const [color, setColor] = useState<string>('')
  const { themeColor } = useThemeContext()

  const newColor = (color: string) => opacity ? `${color}/${opacity}` : color

  useEffect(() => {
    switch (themeColor) {
      case 'green':
        setColor(newColor('text-lime-600'))
        break;
      case 'blue':
        setColor(newColor('text-blue-700'))
        break;
      case 'red':
        setColor(newColor('text-rose-700'))
        break;
      default:
        setColor(newColor('text-lime-600'))
        break;
    }
  }, [themeColor])
  return color;
}
export const useGetFromGradient = (): string => {
  const [color, setColor] = useState<string>('')
  const { themeColor } = useThemeContext()

  useEffect(() => {
    switch (themeColor) {
      case 'green':
        setColor('from-lime-600/30')
        break;
      case 'blue':
        setColor('from-blue-700/30')
        break;
      case 'red':
        setColor('from-rose-700/30')
        break;
      default:
        setColor('from-lime-600/30')
        break;
    }
  }, [themeColor])
  return color;
}


export const useGetHexColor = (): string => {
  const [color, setColor] = useState<string>('')
  const { themeColor } = useThemeContext()

  useEffect(() => {
    switch (themeColor) {
      case 'green':
        setColor(tailwindColors.lime[500].toString())
        break;
      case 'blue':
        setColor(tailwindColors.blue[700].toString())
        break;
      case 'red':
        setColor(tailwindColors.rose[700].toString())
        break;
      default:
        setColor(tailwindColors.lime[500].toString())
        break;
    }
  }, [themeColor])
  return color;
}
